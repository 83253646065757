import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77a8e41a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-views" }
const _hoisted_2 = { class: "tab-views-item-title" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable, {
      list: _ctx.tabsView,
      itemKey: 'name',
      "ghost-class": "ghost",
      "chosen-class": "chosenClass",
      animation: "300",
      sort: true,
      "force-fallback": true,
      filter: ".unmover"
    }, {
      item: _withCtx(({ element }) => [
        _createVNode(_component_router_link, {
          to: element.fullPath,
          class: _normalizeClass(element.name === 'HomePage' ? 'unmover' : '')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["tab-views-item", _ctx.$route.path === element.path ? 'tab-views-item-active' : ''])
            }, [
              _createElementVNode("i", {
                class: "iconfont icon-shuaxin",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(0))),
                style: { 'font-size': '12px' }
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(element.meta.title), 1),
              (element.path !== '/home')
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: "iconfont icon-guanbi tab-views-item-del",
                    onClick: _withModifiers(($event: any) => (_ctx.delTabView(element.name, element.fullPath === _ctx.$route.fullPath)), ["prevent"])
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _: 2
        }, 1032, ["to", "class"])
      ]),
      _: 1
    }, 8, ["list"])
  ]))
}