
import { defineComponent, ref, toRefs, reactive, computed } from 'vue'
import TopHeader from './components/header/index.vue'
import navMenu from './components/navMenu/index.vue'
import store from '@/store/index'
import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
  components: {
    navMenu,
    TopHeader,
  },
  setup() {
    const state = reactive({
      selectedKeys: [],
      openKeys: [],
      collapsed: false,
      left: '70px',
    })
    const url = computed(() => {
      console.log(process.env.VUE_APP_MODE)
      return process.env.VUE_APP_MODE === 'prod' ? 'bcic.yiheda.com' : 'test.bcic.yiheda.com'
    })
    const loading = computed(() => {
      return store.state.loading
    })
    const router = useRouter()
    const route = useRoute()
    //面包屑数据从路由中获取
    const aBreadcrumbList = computed(() => {
      console.log(route?.matched.slice(1) || [])
      return route?.matched.slice(1) || []
    })
    function getQueryString(name: string) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    }
    console.log('加载了')
    //从url获取code
    let code = getQueryString('code')
    console.log(code)
    // alert(window.location.origin)
    //如果code和token都不存在就跳转到登录页面
    // if (!code && !store.state.token) {
    //   window.location.href = `${
    //     process.env.VUE_APP_login_uri
    //   }/oauth/authorize?response_type=code&client_id=${
    //     process.env.VUE_APP_client_id
    //   }&redirect_uri=${window.location.origin}&scope=all&state=${
    //     store.state.redirectUrl
    //   }&time=${new Date().getTime()}`
    // }
    //修改code
    store.commit('setCode', code)
    // 如果code存在调用接口获取token
    if (store.state.code) {
      store.dispatch('getToken').then(() => {
        //获取token成功后 查找是否存在跳转页面
        if (store.state.redirectUrl !== '') {
          //获取跳转页面
          let pathName = store.state.redirectUrl
          //跳转页面重置为空
          store.commit('setRedirectUrl', '')
          //跳转到页面
          if (pathName !== '/404') {
            router.push(pathName)
          } else {
            router.push('/')
          }
        } else {
          router.push('/')
        }
      })
    }
    return {
      ...toRefs(state),
      loading,
      url,
      aBreadcrumbList,
    }
  },
  data() {
    return {
      selectedKeys: ref<string[]>(['1']),
    }
  },
})
