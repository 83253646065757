
import { defineComponent, computed, reactive, toRefs } from 'vue'
import { MenuClass } from '@/model/system/menu'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
interface PageData {
  List: MenuClass[]
  activeItem: MenuClass
  activeSmallItemId: string
}
export default defineComponent({
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  name: 'navMenu',
  components: {
    // AppstoreOutlined,
  },
  emits: ['selectMenuId'],
  setup() {
    //获得路由
    const router = useRouter()
    const route = useRoute()
    const activeMenu = computed(() => {
      if (route.matched[2]) {
        return route.matched[2].path
      } else {
        return ''
      }
    })
    let store = useStore()
    const menuList = computed(() => {
      console.log(store.getters.getMenu)

      return store.getters.getMenu
    })
    const pageData: PageData = reactive({
      List: [],
      activeItem: new MenuClass(),
      activeSmallItemId: '',
    })
    const state = reactive({
      selectedKeys: computed(() => [route.path]),
      openKeys: [],
    })
    //跳转
    const changeMenu = (item: MenuClass) => {
      pageData.activeSmallItemId = item.id as string
      router.push(item.targetUrl as string)
    }
    // //选中事件
    // const selectMenu = (item: MenuClass) => {
    //   setTimeout(function () {
    //     pageData.activeItem = item
    //   }, 100)
    //   emit('selectMenuId', item.id)
    // }
    // //关闭子菜单栏
    // const closeSmallItem = () => {
    //   pageData.activeItem = new MenuClass()
    //   pageData.activeItem.id = '0'
    //   emit('selectMenuId', pageData.activeItem.id)
    // }
    return {
      ...toRefs(pageData),
      ...toRefs(state),
      changeMenu,
      // selectMenu,
      // closeSmallItem,
      activeMenu,
      menuList,
    }
  },
})
