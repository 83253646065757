
import { reactive, toRefs, computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import store from '@/store/index'
import formItem from '@/components/formItem/index.vue'
import { UserClass } from '@/model/system/user'
import userApi from '@/axios/api/user'
import { message } from 'ant-design-vue'
import tabView from './tabView.vue'
export default {
  name: 'TopHeader',
  components: {
    formItem,
    tabView,
  },
  setup(): unknown {
    //获得路由
    const route = useRoute()
    console.log(route.matched)
    const router = useRouter()
    const formRef = ref()
    const onSearch = () => {
      console.log('搜索中')
    }
    // deleteModal()
    //重复密码验证规则自定义
    const checkPassword = (rule: unknown, value: string) => {
      if (!value) {
        return Promise.reject('不能为空')
      } else if (value !== pageData.userAccountForm.newLoginPassword) {
        return Promise.reject('两次密码输入不一致')
      } else {
        return Promise.resolve()
      }
    }
    const pageData = reactive({
      value: '',
      userInfo: {} as UserClass,
      userAccountForm: {
        loginPassword: '',
        newLoginPassword: '',
        repeatPassword: '',
      },
      userAccountFormList: [
        {
          type: 'subInput',
          prop: 'loginPassword',
          label: '原密码：',
          placeholder: '请输入原密码',
          inputType: 'password',
        },
        {
          type: 'subInput',
          prop: 'newLoginPassword',
          label: '新密码：',
          placeholder: '请输入新密码',
          inputType: 'password',
        },
        {
          type: 'subInput',
          prop: 'repeatPassword',
          label: '再次输入：',
          placeholder: '请输入新密码',
          inputType: 'password',
        },
      ],
      rules: {
        loginPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newLoginPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 18, message: '密码长度在6位到18位', trigger: 'blur' },
        ],
        repeatPassword: [{ required: true, validator: checkPassword, trigger: 'blur' }],
      },
      visible: false,
    })
    const aBreadcrumbList = computed(() => {
      return route?.matched || []
    })
    const handleOk = () => {
      formRef.value
        .validate()
        .then(() => {
          userApi
            .updatePassword({
              loginPassword: pageData.userAccountForm.loginPassword,
              newLoginPassword: pageData.userAccountForm.newLoginPassword,
            })
            .then(() => {
              message.success('成功')
              pageData.visible = false
            })
            .catch((err) => {
              message.error(err)
            })
        })
        .catch(() => {
          console.log('验证失败')
        })
    }
    const close = () => {
      console.log(123)
      console.log(formRef.value)

      formRef.value.resetFields()
    }
    //退出登录
    const logOut = () => {
      // store.commit('LOGOUT')
      console.log('退出')
      let token = store.state.token
      store.commit('setCode', '')
      store.commit('setToken', '')
      store.commit('setRefreshToken', '')
      store.commit('setWatermark', '')
      window.location.href = `${process.env.VUE_APP_login_uri}/logout?access_token=${token}&response_type=code&client_id=${process.env.VUE_APP_client_id}&redirect_uri=${window.location.origin}&scope=all&state=${store.state.redirectUrl}`
    }
    const getDepartmentInfo = () => {
      userApi
        .getDepartmentInfo()
        .then((res) => {
          store.commit('setWatermark', `${res.name}  ${res.code}`)
          pageData.userInfo.name = res.name as string
        })
        .catch((err) => {
          message.error(err)
        })
    }
    watch(
      () => store.state.token,
      () => {
        if (store.state.token) {
          getDepartmentInfo()
        }
      }
    )
    if (store.state.token) {
      getDepartmentInfo()
    }
    const go = (path: string, i: number) => {
      console.log(i !== 1 && i !== aBreadcrumbList.value.length - 1, path)

      if (i !== 1 && i !== aBreadcrumbList.value.length - 1) {
        router.push(path)
      }
    }
    return {
      ...toRefs(pageData),
      onSearch,
      aBreadcrumbList,
      logOut,
      handleOk,
      close,
      formRef,
      go,
    }
  },
}
