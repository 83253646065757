/**
 * menu菜单实体类和接口
 */
import { ReqPage } from '../common/reqPage'

// 菜单类
export class MenuClass {
  id: string | null // "string //主键"
  menuName: string | null // 菜单名称【必须】"
  targetUrl: string | null // "string //目标地址【必须】"
  sort: number | null // int // 排序【必须】"
  linkSystemId: string | null // string //关联系统id"
  linkSystemName: string | null // string //关联系统id"
  openType: number | null // "int //打开方式 默认值 1 1：-工作区 2：-新窗口【必须】"
  icon: string | null // "string //图标"
  parentId: string | null // "string //父菜单id"
  isWhitelist: number | null // "int //是否白名单 默认 0 0：否 1：是"
  urlType: number | null // 链接类型 1:导航 2:功能 3:系统"
  createdBy: string | null // 创建人"
  createdTime: string | null // date 创建时间"
  updatedBy: string | null // "string //更新人
  updatedTime: string | null // date //更新时间
  isEnable: number | null // "int //是否启用【必须】"
  isDelete: number | null // "int //是否删除 0 未删除 2 已删除"
  deleteTime: string | null // "date //删除时间"
  iconArr: Array<string> | null // 图标集合
  subLinkMenu: Array<MenuType> | null // 子集列表
  apiUrl: string | null // 请求地址

  selectState: number | null // 2021-7-28 0半选，1全选

  isShowInBar: number | null //2021-08-20 是否在导航栏显示 默认 0 0：不显示 1：显示
  ex2: string | null // 预留字段
  ex3: string | null // 预留字段
  ex4: string | null // 预留字段
  ex5: string | null // 预留字段
  ex6: string | null // 预留字段
  constructor() {
    this.id = null
    this.menuName = null
    this.targetUrl = null
    this.sort = null
    this.linkSystemId = null
    this.linkSystemName = null
    this.openType = null
    this.icon = null
    this.parentId = null
    this.isWhitelist = null
    this.urlType = null
    this.createdBy = null
    this.createdTime = null
    this.updatedBy = null
    this.updatedTime = null
    this.isEnable = null
    this.isDelete = null
    this.deleteTime = null
    this.iconArr = null
    this.subLinkMenu = null
    this.apiUrl = null
    this.isShowInBar = null
    this.selectState = null
    this.ex2 = null
    this.ex3 = null
    this.ex4 = null
    this.ex5 = null
    this.ex6 = null
  }
}
// 菜单类型
export type MenuType = MenuClass

// 菜单列表请求类
export class MenuReqClass extends ReqPage {
  data: MenuType
  constructor() {
    super()
    this.data = new MenuClass()
  }
}
// 菜单列表请求类型
export type MenuReqType = MenuReqClass
