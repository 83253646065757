
import { defineComponent, computed, watch } from 'vue'
import store from '@/store/index'
import { useRoute } from 'vue-router'
import { MissingType } from '@/types/comm'
import Data from '@/model/common/Data'
import draggable from 'vuedraggable'
interface RouteItem {
  fullPath: string
  hash?: MissingType
  meta?: string
  name?: string
  params?: Data
  path?: string
  query?: Data
}
export default defineComponent({
  components: { draggable },
  setup() {
    const route = useRoute()
    // 获取简易的路由对象
    const getSimpleRoute = (route: MissingType): RouteItem => {
      const { fullPath, hash, meta, name, params, path, query } = route
      return { fullPath, hash, meta, name, params, path, query }
    }
    watch(
      () => route.path,
      () => {
        console.log(getSimpleRoute(route), 'path')
        store.commit('addTabsView', getSimpleRoute(route))
      },
      { immediate: true }
    )
    //获取tabView
    const tabsView = computed(() => {
      console.log(store.state.tabsView, 'tabsView')
      return store.state.tabsView
    })
    //删除
    const delTabView = (name: string, isCurrent: boolean) => {
      store.commit('delTabsView', { name, isCurrent })
    }
    return {
      tabsView,
      delTabView,
    }
  },
})
